import Product from './Product';

export default class InvoiceLine {
    public vatPercentage: number;
    public unitAmount: number = 1;
    public amount: number;
    public name: string = '';
    public details: string = '';
    public invoiceId: number;
    public number: number;
    public startDate: string;
    public endDate: string;
    public financeAccountId: number;
    public siteId: number;
    public IsDeleted: boolean = false;
}
