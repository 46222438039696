import BasePage from './BasePage';
import { invoiceService, bookingService, bookerService, productService } from '@/main';
import to from 'await-to-js';
import InvoiceLine from './InvoiceLine';
import { Invoice } from './Invoice';
import { AxiosResponse } from 'axios';
import Booking from './Booking';
import Booker from './Booker';
import { Watch } from 'vue-property-decorator';
import Product from './Product';

export default class InvoiceBasePage extends BasePage {
    public invoice: Invoice = { invoiceId: null, invoiceLines: [], mainBooker: null, booking: null, status: 0 } as Invoice;

    public newInvoiceState: number = null;
    public newInvoiceStateObject: any = null;
    public paymentMethodObject: any = null;
    public paymentMethod: string = null;

    public invoiceStates: any[] = [
        { value: 3, text: 'Deels betaald' },
        { value: 4, text: 'Betaald' },
    ];

    public paymentOptions: any[] = [
        { value: 'Cash', text: 'Contant' },
        { value: 'DebitCard', text: 'Pin' },
        { value: 'CreditCard', text: 'Creditcard' },
        { value: 'Settlement', text: 'Bankoverschrijving' },
    ];

    @Watch('newInvoiceStateObject')
    public onStateChange(val, oldVal) {
        if (val) {
            this.newInvoiceState = val.value;
        }
    }

    @Watch('paymentMethodObject')
    public onPaymentMethodChange(val, oldVal) {
        if (val) {
            this.paymentMethod = val.value;
        }
    }

    public async getBookings(
        bookingState?: number,
        start?: string,
        end?: string,
        arrivalDate?: string,
        departureDate?: string,
        checkedIn?: boolean,
        mainBookerId?: number,
    ) {
        const [err, response] = await to<AxiosResponse<Booking[]>>(
            bookingService.getBookings(this.site.siteId, bookingState, start, end, checkedIn, arrivalDate, departureDate, mainBookerId),
        );
        if (err) {
            this.showError('Mislukt om de boekingen op te halen');
        }
        return response.data;
    }

    public getTotalVat(): number {
        let total = 0;

        if (this.invoice.invoiceLines && this.invoice.invoiceLines.length >= 1) {
            this.invoice.invoiceLines.forEach((invoiceLine: InvoiceLine) => {
                total += (invoiceLine.amount / (100 + invoiceLine.vatPercentage)) * invoiceLine.vatPercentage;
            });
        }

        return total;
    }

    public getTotalPrice(): number {
        let total = 0;
        if (this.invoice.invoiceLines && this.invoice.invoiceLines.length >= 1) {
            this.invoice.invoiceLines.forEach((invoiceLine: InvoiceLine) => {
                total += invoiceLine.amount;
            });
        }

        return total;
    }

    public async getInvoice(invoiceId) {
        const [err, response] = await to(invoiceService.getInvoiceById(invoiceId));
        if (err) {
            this.showError('Mislukt om concept factuur op te halen');
        }
        return response.data;
    }

    public async getBookers() {
        const [err, response] = await to<AxiosResponse<Booker[]>>(bookerService.getBookers(this.site.siteId));
        if (err) {
            this.showError('Mislukt om hoofdboekers op te halen');
        }
        return response.data;
    }

    public async getProducts(isRegisterApplicable?: boolean): Promise<Product[]> {
        const [err, response] = await to(productService.getProducts(this.site.siteId, isRegisterApplicable));
        if (err) {
            this.showError('Producten & kortingen ophalen mislukt');
        }
        return response.data;
    }
}
