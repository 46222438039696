import { Watch } from 'vue-property-decorator';
import Component from 'vue-class-component';
import InvoiceLine from '../../models/InvoiceLine';
import Product from '../../models/Product';
import Booker from '../../models/Booker';
import to from 'await-to-js';
import { invoiceService, dictionaryHelper } from '@/main';
import Booking from '../../models/Booking';
import { BModal } from 'bootstrap-vue';
import InvoiceBasePage from '@/models/InvoiceBasePage';

export default class CreateInvoiceBasePage extends InvoiceBasePage {
    public isLoadingBookings: boolean = false;
    public bookers: Booker[] = [];
    public allBookings: Booking[] = [];
    public bookings: Booking[] = [];
    public products: Product[] = [];
    public invoiceLine: InvoiceLine = new InvoiceLine();
    public isOrderLineProductInvalid: boolean = false;
    public selectedProduct: Product = null;
    public invoiceLineDetails: string = '';
    public dictionairy = dictionaryHelper;
    public registerInvoiceId: number = null;
    public invoiceHtml: string = '';
    public InvoiceIdForStateChange: number = null;
    public newInvoiceStateAmount: string = '0';

    public booking: Booking = null;
    public mainBooker: Booker = null;
    public directlyFromBooking: boolean = false;
    public isSingleSale: boolean = false;

    public $refs!: {
        paymentReceived: BModal;
    };

    public getName(booker: Booker) {
        return [booker.firstName, booker.insertion, booker.lastName, '(' + booker.emailAddress + ')'].join(' ');
    }

    public getBookingName(booking: Booking) {
        return 'bk-' + booking.bookingId;
    }

    public getInvoiceMainbooker() {
        if (this.invoice.mainBookerId && this.invoice.mainBooker) {
            return [
                this.invoice.mainBooker.firstName,
                this.invoice.mainBooker.insertion,
                this.invoice.mainBooker.lastName,
                '(' + this.invoice.mainBooker.emailAddress + ')',
            ].join(' ');
        }

        return 'Niet gekoppeld';
    }

    public getInvoiceBookingNumber() {
        if (this.invoice.booking) {
            return `bk-${this.invoice.booking.bookingId}`;
        }

        return 'Niet gekoppeld';
    }

    public addInvoiceLineToOrder(line: InvoiceLine) {
        if (!this.selectedProduct) {
            this.isOrderLineProductInvalid = true;
            this.clearAndShowError('Zorg ervoor dat er een product is geslecteerd om toe te voegen', null);
            return;
        }

        line.name = this.selectedProduct.description;
        line.details = this.invoiceLineDetails;
        line.unitAmount = this.selectedProduct.discountAmount;
        line.vatPercentage = this.selectedProduct.vat.percentage;
        line.financeAccountId = this.selectedProduct.financeAccountId;

        if (!line.number) {
            line.number = 1;
        }

        line.amount = line.number * line.unitAmount;

        this.invoiceLineDetails = '';
        this.selectedProduct = null;

        this.isOrderLineProductInvalid = false;

        this.invoice.invoiceLines.push(line);
        this.invoiceLine = new InvoiceLine();
    }

    public removeInvoiceLineFromOrder(index: number) {
        this.invoice.invoiceLines.splice(index, 1);
    }

    public async print() {
        this.showPending('Factuur genereren...');
        const [err, response] = await to(invoiceService.getInvoiceHtml(this.registerInvoiceId));

        this.invoiceHtml = response.data;
        this.printElem(this.invoiceHtml);

        this.clearNotifications();
    }

    public async mail() {
        const self = this;

        self.showPending('Factuur versturen...');

        const [err, response] = await to(invoiceService.mailInvoice(this.invoice.invoiceId));
        if (err) {
            return self.clearAndShowError('Factuur versturen mislukt.', err);
        }

        self.clearAndShowSuccess('Factuur verstuurd.');
    }

    public async downloadInvoice() {
        const self = this;

        self.showPending('Factuur download voorbereiden...');

        const [err, response] = await to(invoiceService.downloadInvoice(this.invoice.invoiceId));
        if (err) {
            return self.clearAndShowError('Factuur downloaden mislukt.', err);
        }

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${this.invoice.invoiceNumber}-${new Date().toLocaleTimeString()}.pdf`);
        document.body.appendChild(link);
        link.click();

        self.clearAndShowSuccess('Factuur gedownload.');
    }

    public async deleteInvoice(invoice) {
        this.showPending('Factuur verwijderen...');

        const [err, response] = await to(invoiceService.updateInvoiceState(invoice.invoiceId, this.site.siteId, 7));
        if (err) {
            return this.clearAndShowError('Factuur verwijderen mislukt.', err);
        }

        this.clearAndShowSuccess('Factuur verwijderd.');
    }

    public async createCreditInvoice() {
        this.showPending('Factuur crediteren...');

        const [err, response] = await to(invoiceService.createCreditInvoice(this.invoice.invoiceId));
        if (err) {
            return this.clearAndShowError('Factuur credtieren mislukt.', err);
        }

        this.clearAndShowSuccess('Creditfactuur aangemaakt.');
    }

    public async sendIdealLink() {
        const invoiceId = this.invoice.invoiceId;

        this.showPending('Betaal link genereren en versturen..');

        const [err, response] = await to(invoiceService.sendIdealLinkMail(invoiceId));
        if (err) {
            return this.clearAndShowError('Betaal link genereren en versturen is mislukt', err);
        }

        this.invoice.status = dictionaryHelper.get('InvoiceStates')[2];

        this.clearAndShowSuccess('Betaal link succesvol verstuurd.');
        this.editMode = false;
    }

    public async changeInvoiceStatus(invoiceId, siteKey, newState, paidAmount?: string) {
        this.showPending('Factuur updaten...');

        const [err, response] = await to(invoiceService.updateInvoiceState(invoiceId, this.site.siteId, newState));
        if (err) {
            return this.clearAndShowError('Factuur updaten mislukt.', err);
        }

        this.clearAndShowSuccess('Factuurstatus geupdatet.');
    }

    public showInvoiceStatusChangeModal() {
        this.InvoiceIdForStateChange = this.invoice.invoiceId;
        this.$refs.paymentReceived.show();
    }

    public printElem(html) {
        const mywindow = window.open('', 'PRINT', 'height=800,width=1200');

        mywindow.document.write(html);
        mywindow.document.close();
        mywindow.focus();

        setTimeout(() => {
            mywindow.document.close();
            mywindow.focus();
            mywindow.print();
        }, 1000);

        return true;
    }

    public async save(isSingleSale: boolean = false, doRedirect?: boolean) {
        this.showPending('Factuur aanmaken...');
        if (this.invoice.mainBooker) {
            this.invoice.mainBookerId = this.invoice.mainBooker.mainBookerId;
        }

        if (this.booking) {
            this.invoice.bookingId = this.booking.bookingId;
        }

        this.invoice.isRegisterSale = isSingleSale;

        let [err, response] = [null, null];

        if (!this.invoice.invoiceId) {
            [err, response] = await to(invoiceService.createInvoice(this.invoice, this.site.siteId));
            if (err || !response) {
                this.clearAndShowError('Mislukt om een factuur aan te maken.', err);
                return;
            }

            this.invoice.invoiceId = this.registerInvoiceId = response.data.invoice.invoiceId;
        } else {
            [err, response] = await to(invoiceService.updateInvoice(this.invoice, this.site.siteId));
            if (err || !response) {
                this.clearAndShowError('Mislukt om een factuur aan te maken.', err);
                return;
            }
        }

        this.clearNotifications();
        this.editMode = false;

        if (doRedirect) {
            this.$router.push({ name: 'invoices', params: { siteKey: this.siteKey } });
        } else {
            return response.data;
        }
    }

    public async recievePayment() {
        this.showPending('Betaling verwerken...');

        if (!this.invoice.invoiceId) {
            const saveResponse = await this.save(this.isSingleSale);
            this.invoice = saveResponse.invoice;
            this.registerInvoiceId = saveResponse.invoice.invoiceId;
        }

        const amoundPaid = this.newInvoiceState === 3 ? this.newInvoiceStateAmount : null;
        const [err, response] = await to(
            invoiceService.makeInvoicePayment(this.invoice.invoiceId, this.site.siteId, this.paymentMethod, amoundPaid),
        );
        if ((err || !response) && !this.registerInvoiceId) {
            this.clearAndShowError('Mislukt om de factuur te markeren als betaald.', err);
            return;
        }

        this.clearAndShowSuccess('Factuur aangemaakt en gemarkeerd als betaald.');
        this.invoice.status = this.newInvoiceState;
        if (this.invoice.mainBookerId) {
            this.mainBooker = this.invoice.mainBooker = this.bookers.find((x) => x.mainBookerId === this.invoice.mainBookerId);
        }
        if (this.invoice.bookingId) {
            this.booking = this.invoice.booking = this.bookings.find((x) => x.bookingId === this.invoice.bookingId);
        }
        this.invoice.amountPaid = parseInt(this.newInvoiceStateAmount, 10);
        this.editMode = false;
        this.$refs.paymentReceived.hide();
    }
}
